"use client";
import { useEffect, useState } from "react";
import { P } from "../../tags/TextElements";
import { z, ZodError } from "zod";
import { KITA_SEARCH, validMediums } from "./constants";
import { onboardingSchema } from "./schema";
import { maxDate, minDate } from "./constants";
import clsx from "clsx";
import { Modal } from "../../Modal";
import { locationsData } from "@/app/constants";
import dynamic from "next/dynamic";
import { capitalize } from "@/app/lib/capitalize";
import { SubmitBtn } from "../../SubmitBtn";

const Select = dynamic(() => import("react-select"), { ssr: false });

const fieldOrder = ["name", "dob", "district", "stage", "medium"];

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles: React.CSSProperties = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

export function OnboardingModal({
  t,
  formAction,
  formSuccessState,
  setIsModalOpen,
}) {
  const [isOther, setIsOther] = useState(false);
  const [isOtherCity, setIsOtherCity] = useState(false);
  const [isReferral, setIsReferral] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [fieldStatus, setFieldStatus] = useState({
    firstName: "untouched",
    lastName: "untouched",
    stage: "untouched",
    dob: "untouched",
    district: "untouched",
    medium: "untouched",
  });
  const [menuPortalTarget, setMenuPortalTarget] = useState<HTMLElement | null>(
    null,
  );

  const groupedOptions = Object.entries(locationsData["Germany"].cities).map(
    (location, i) => {
      const otherCityLabel = (loc) => {
        return loc.toLowerCase() === "all_other_cities"
          ? "Another City"
          : capitalize(loc);
      };
      return {
        label: otherCityLabel(location[0]),
        options: location[1].map((district) => ({
          value: district.toLowerCase(),
          label: otherCityLabel(district),
        })),
      };
    },
  );

  useEffect(() => {
    if (typeof document !== "undefined") {
      setMenuPortalTarget(document.body);
    }
  }, []);

  useEffect(() => {
    if (formSuccessState !== "success") return;
    setTimeout(() => {
      setIsModalOpen(false);
      window.location.reload();
    }, 1500);
  }, [formSuccessState, setIsModalOpen]);

  const handleInputChange = (event, field?) => {
    let name;
    let value;
    if (event.target) {
      const { name: n, value: v } = event.target;
      name = n;
      value = v;
    } else if (field) {
      name = field;
      value = event.value;
    }

    try {
      const partialSchema = z.object({
        [name]: onboardingSchema.shape[name],
      });
      partialSchema.parse({ [name]: value });
      setFieldStatus((prevData) => ({ ...prevData, [name]: "valid" }));

      if (name === "medium") {
        setIsOther(value === "Other");
        setIsReferral(value === "Someone you know");
      }
      if (name === "district") {
        setIsOtherCity(value === "all_other_cities");
      }
    } catch (error) {
      if (error instanceof ZodError) {
        setFieldStatus((prevData) => ({
          ...prevData,
          [name]: "invalid",
        }));
      }
    }
  };

  const isNameStepValid =
    fieldStatus.firstName === "valid" && fieldStatus.lastName === "valid";

  return (
    <Modal>
      <div>
        <h1 className="text-center text-2xl text-primary">{t.welcome}</h1>
        <P extraClasses="mt-4 text-center">{t.questionsIntro}</P>
      </div>
      <div className="divider" />

      <ul className="steps mb-5 w-full">
        {fieldOrder.map((_, index) => (
          <li
            key={index}
            className={clsx(
              "step",
              currentQuestionIndex >= index ? "step-primary" : "",
            )}
          ></li>
        ))}
      </ul>
      <form action={formAction}>
        {/* name */}
        <div className={clsx(currentQuestionIndex === 0 ? "block" : "hidden")}>
          <div className="form-control">
            <p className="text-lg">{t.nameLabel}</p>
            <div className="flex space-x-4">
              <input
                type="text"
                name="firstName"
                placeholder={t.firstNamePlaceholder}
                onChange={handleInputChange}
                required
                className={clsx("input input-bordered w-40", {
                  "input-error": fieldStatus.firstName === "invalid",
                })}
              />
              <input
                type="text"
                name="lastName"
                placeholder={t.lastNamePlaceholder}
                onChange={handleInputChange}
                required
                className={clsx("input input-bordered w-40", {
                  "input-error": fieldStatus.lastName === "invalid",
                })}
              />
            </div>
            {fieldStatus.lastName === "invalid" && (
              <small className="mt-1 block text-sm text-error">
                {t.nameValidationError}
              </small>
            )}
          </div>
          <div className="divider" />
        </div>
        {/* dob */}
        <div className={clsx(currentQuestionIndex === 1 ? "block" : "hidden")}>
          <p className="text-lg">{t.childBirthDateLabel}</p>
          <div>
            <input
              type="date"
              name="dob"
              onChange={handleInputChange}
              min={minDate.toISODate()}
              max={maxDate.toISODate()}
              required
              className={clsx("input input-bordered w-40", {
                "input-error": fieldStatus.dob === "invalid",
              })}
            />
            {fieldStatus.dob === "invalid" && (
              <small className="mt-1 block text-sm text-error">
                {t.dobValidationError}
              </small>
            )}
          </div>
          <div className="divider" />
        </div>
        {/* district */}
        <div className={clsx(currentQuestionIndex === 2 ? "block" : "hidden")}>
          <p className="text-lg">{t.neighborhoodLabel}</p>
          <div>
            <Select
              menuPortalTarget={menuPortalTarget}
              required
              defaultValue={{ value: "", label: t.chooseDistrict }}
              name="district"
              onChange={(e) => handleInputChange(e, "district")}
              formatGroupLabel={formatGroupLabel}
              options={groupedOptions}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
            />
            <div className={clsx("mt-2", isOtherCity ? "block" : "hidden")}>
              <input
                type="text"
                name="otherCity"
                className="w-38 input input-bordered"
              />
            </div>
          </div>
          <div className="divider" />
        </div>
        {/* stage */}
        <div className={clsx(currentQuestionIndex === 3 ? "block" : "hidden")}>
          <div className="form-control">
            <p className="text-lg">{t.describeYourself}</p>
            {[KITA_SEARCH, ""].map((value) => (
              <label key={value} className="label cursor-pointer justify-start">
                <input
                  type="radio"
                  name="stage"
                  onChange={handleInputChange}
                  value={value}
                  className="radio checked:bg-primary"
                  required
                />
                <span className="label-text ml-2">
                  {!!value ? t.childBornLabel : t.expectingLabel}
                </span>
              </label>
            ))}
          </div>
          <div className="divider" />
        </div>
        {/* medium */}
        <div className={clsx(currentQuestionIndex === 4 ? "block" : "hidden")}>
          <p className="text-lg">{t.mediumLabel}</p>
          <div>
            <select
              className="select select-bordered w-48"
              required
              defaultValue={""}
              name="medium"
              onChange={handleInputChange}
            >
              <option value="" disabled>
                {t.chooseMedium}
              </option>
              {validMediums.map((medium) => (
                <option key={medium} value={medium}>
                  {medium}
                </option>
              ))}
            </select>
            <div className={clsx("mt-2", isOther ? "block" : "hidden")}>
              <input
                type="text"
                name="otherMedium"
                className="w-38 input input-bordered"
              />
            </div>
            <div className={clsx("mt-2", isReferral ? "block" : "hidden")}>
              <small className="mt-1 block text-sm italic text-base-300">
                {t.referralLabel}
              </small>
              <input
                type="text"
                name="referral"
                className="w-38 input input-bordered"
              />
            </div>
          </div>

          <div className="divider" />
        </div>
        <div className="flex justify-start">
          {currentQuestionIndex === 4 ? (
            <>
              <SubmitBtn
                isFormValid={
                  fieldStatus.firstName === "valid" &&
                  fieldStatus.lastName === "valid" &&
                  fieldStatus.district === "valid" &&
                  fieldStatus.dob === "valid" &&
                  fieldStatus.stage === "valid" &&
                  fieldStatus.medium === "valid"
                }
                isSuccess={formSuccessState === "success"}
              >
                {t.submitBtn}
              </SubmitBtn>
              {formSuccessState === "error" && (
                <small className="ml-2 mt-1 block text-sm text-error">
                  {t.submitError}
                </small>
              )}
            </>
          ) : (
            <button
              type="button"
              onClick={() =>
                setCurrentQuestionIndex((prevIndex) => prevIndex + 1)
              }
              className="btn btn-primary"
              disabled={
                currentQuestionIndex === 0
                  ? !isNameStepValid
                  : fieldStatus[fieldOrder[currentQuestionIndex]] !== "valid"
              }
            >
              Next
            </button>
          )}
        </div>
      </form>
    </Modal>
  );
}
