import { Check } from "@/app/svg/icons/check/Check";
import clsx from "clsx";
import { useFormStatus } from "react-dom";

export const SubmitBtn = ({
  children,
  isFormValid,
  isFullWidth,
  isSuccess,
  btnColor = "btn-secondary",
}: {
  children: React.ReactNode;
  isFormValid: boolean;
  isFullWidth?: boolean;
  isSuccess: boolean;
  btnColor?: string;
}) => {
  const { pending } = useFormStatus();

  return (
    <button
      className={clsx("btn transition-all duration-500", {
        [btnColor]: !isSuccess,
        "btn-success text-neutral": isSuccess,
        "btn-disabled": !isFormValid,
        "pointer-events-none": pending || isSuccess || !isFormValid,
        "w-full": isFullWidth,
      })}
      disabled={pending}
    >
      {pending ? (
        <>
          Loading...
          {""}
          <span className="loading loading-spinner loading-xs" />
        </>
      ) : isSuccess ? (
        <>
          Success!
          <Check />
        </>
      ) : (
        <>{children}</>
      )}
    </button>
  );
};
