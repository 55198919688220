import { DateTime } from "luxon";

export const validMediums = [
  "Another site",
  "Facebook",
  "Google",
  "Instagram",
  "Someone you know",
  "WhatsApp Group",
  "Other",
];

export const KITA_SEARCH = "kita_search" as const;
export const EXPECTING = "expecting" as const;

const today = DateTime.local();

export const maxDate = today.plus({ months: 11 });
export const minDate = today.minus({ years: 6 });
