"use client";

import { useUserData } from "@/app/[locale]/hooks/useUserData";
import { MaybeOnboardingModal } from "./MaybeOnboardingModal";
import { usePathname, useRouter } from "@/navigation";
import MonitoringService from "@/app/lib/monitoringService";
import { PASSWORD_RESET_LINK, PASSWORD_SET_LINK } from "@/app/constants";
import { useEffect } from "react";

export function Onboarding({ text }) {
  const pathname = usePathname();
  const router = useRouter();
  const { data: user, isLoading, isError, error } = useUserData();

  useEffect(() => {
    if (isLoading) return;
    if (!user?.isLoggedIn) return;
    if (!user?.isSubscribed) return;
    if (pathname === PASSWORD_SET_LINK) return;
    if (pathname === PASSWORD_RESET_LINK) return;

    if (!user.isPasswordUpdated) {
      router.push(PASSWORD_SET_LINK);
    }
  }, [isLoading, user, pathname, router]);

  if (isError) {
    MonitoringService.notify(error);
    return null;
  }
  if (isLoading) return null;
  if (!user?.isLoggedIn) return null;
  if (!user?.isSubscribed) return null;
  if (pathname === PASSWORD_SET_LINK) return null;
  if (pathname === PASSWORD_RESET_LINK) return null;

  return <MaybeOnboardingModal t={text} isOnboarded={user.isOnboarded} />;
}
