import { DateTime } from "luxon";
import { maxDate, minDate, validMediums } from "./constants";
import { z } from "zod";
import { validDistricts } from "@/app/constants";

export const onboardingSchema = z.object({
  firstName: z.string().min(2),
  lastName: z.string().min(2),
  stage: z.string().refine((val) => ["", "kita_search"].includes(val)),
  dob: z.string().refine((dateString) => {
    const parsedDate = DateTime.fromISO(dateString);
    return parsedDate.isValid && parsedDate >= minDate && parsedDate <= maxDate;
  }),
  district: z
    .string()
    .refine((value) => validDistricts.find((v) => v.key === value)),
  medium: z.string().refine((value) => validMediums.includes(value)),
  otherMedium: z
    .string()
    .refine((value) => value.length > 0)
    .optional(),
  referral: z
    .string()
    .refine((value) => value.length > 0)
    .optional(),
});
