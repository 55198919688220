"use client";
import { useEffect, useState } from "react";
import { useFormState } from "react-dom";
import { submitOnboarding } from "@/app/actions";
import { OnboardingModal } from "./OnboardingModal";
import { useLocale } from "next-intl";

export function MaybeOnboardingModal({ isOnboarded, t }) {
  const locale = useLocale();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const submitOnboardingWithLocale = submitOnboarding.bind(null, locale);
  const [formState, formAction] = useFormState(
    submitOnboardingWithLocale,
    null,
  );

  useEffect(() => {
    if (!isOnboarded) {
      setTimeout(() => {
        setIsModalOpen(true);
      }, 300);
      return;
    }
  }, [isOnboarded]);

  if (!isModalOpen) {
    return null;
  }

  return (
    <OnboardingModal
      formAction={formAction}
      formSuccessState={formState}
      t={t}
      setIsModalOpen={setIsModalOpen}
    />
  );
}
